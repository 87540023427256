<template>
    <div class="row">
        <div class="col-lg-12">
            <tab-card>
                <template #nav>
                    <tab-nav active name="edit-profile" icon="bx bx-edit-alt font-size-20" :text="$t('base.edit').ucFirst()"></tab-nav>
                    <tab-nav v-if="user?.id" name="hierarchy" icon="bx bx-sitemap font-size-20" :text="$tc('users.organogram',2).ucFirst()"></tab-nav>
                    <tab-nav v-if="user?.id" name="targets" icon="bx bx-bullseye font-size-20" :text="$tc('projects::target_values.target_value',2).ucFirst()"></tab-nav>
                </template>
                <template #content>
                    <tab-panel active name="edit-profile">
                        <UserForm :user="user"></UserForm>
                    </tab-panel>
                    <tab-panel v-if="user?.id" name="hierarchy">
                        <superior-form :owner="user"></superior-form>
                        <div class="row">
                            <div class="col-lg-6">
                                <position-manager :user-id="user?.id"></position-manager>
                            </div>
                            <div class="col-lg-6">
                                <skill-manager :user-id="user?.id"></skill-manager>
                            </div>
                        </div>
                    </tab-panel>
                    <tab-panel v-if="user?.id" name="targets">
                        <target-value-form :user="user"></target-value-form>
                    </tab-panel>
                </template>

            </tab-card>
        </div>
    </div>
    <!-- end row -->
</template>

<script>
import {mapGetters} from "vuex";
import UserForm from "../../../components/forms/user-form.vue";
import SuperiorForm from "../../../components/forms/superior-form.vue";
import TabNav from "../../../components/elements/tab-nav.vue";
import TabCard from "../../../components/elements/tab-card.vue";
import TabPanel from "../../../components/elements/tab-panel.vue";
import TargetValueForm from "../../../components/forms/target-value-form.vue";
import PositionManager from "../../../components/elements/user/position-manager.vue";
import SkillManager from "../../../components/elements/user/skill-manager.vue";

export default {
    components: {
        SkillManager,
        PositionManager,
        TargetValueForm,
        TabPanel,
        TabCard,
        TabNav,
        SuperiorForm,
        UserForm},

    computed: {
        ...mapGetters({
            user: 'user/item'
        }),

        title: function () {
            return this.user && this.user.id ? this.$tc('users.user', 2).ucFirst() + ' / ' + this.user.name : this.$t('base.create_new_item', {item: this.$tc('users.user', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/users')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('user/show', {id: this.$route.params.id, query: { with: ['implementer'] }})
        }
    },

    unmounted() {
        this.$store.dispatch('user/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
