<script>
import TransitionToggle from "../transitions/toggle.vue";
import SimpleTable from "./simple-table.vue";
import CustomTargetValueForm from "../forms/custom-target-value-form.vue";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
    name: "custom-target-value-manager",

    components: {CustomTargetValueForm, SimpleTable, TransitionToggle},

    props: {
        userId: {
            type: [String, Number],
            required: true
        }
    },

    computed: {
        ...mapGetters({
            items: 'customTargetValue/all'
        }),

        fields: function () {
            return [
                {
                    key: 'name',
                    label: this.$t('projects::custom_target_values.columns.name').ucFirst(),
                    formatter: (value, index, item) => item.translation_key ? this.$t(item.translation_key).ucFirst() : value
                },
                {
                    key: 'icon',
                    label: this.$t('projects::custom_target_values.columns.icon').ucFirst()
                },
                {
                    key: 'type',
                    label: this.$t('projects::custom_target_values.columns.type').ucFirst(),
                    formatter: (value, index, item) => {
                        const type = this.typeValues.find(type => item?.[type]);

                        return type ? this.$t('dates.'+type).ucFirst() : null;
                    }
                },
                {
                    key: 'value',
                    label: this.$t('projects::custom_target_values.columns.value').ucFirst(),
                    formatter: (value, index, item) => {
                        const type = this.typeValues.find(type => item?.[type]);

                        return item?.[type];
                    }
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    tdClass: 'text-end'
                }
            ];
        },
    },

    data: function () {
        return {
            showForm: false,
            item: {
                user_type: 'App\\Models\\User',
                user_id: this.userId
            },
            typeValues: ['daily', 'weekly', 'monthly', 'yearly']
        };
    },

    methods: {
        editTableItem: function (item, callback) {
            this.$store.dispatch('customTargetValue/setItem', item).then(() => {
                callback(item)
            })
        },

        removeTableItem: function (item) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('customTargetValue/destroy', item.id)
                }
            });
        },

        updateTableItem: function (item, callback) {
            this.$store.dispatch('customTargetValue/update', item).then(() => {
                this.$store.dispatch('customTargetValue/clearItem')
                callback()
            })
        },

        add: function (item) {
            this.$store.dispatch('customTargetValue/create', item).then(() => {
                this.$store.dispatch('customTargetValue/clearItem')
                this.showForm = false
            })
        },

        clearItem: function () {
            this.item = {user_type: 'App\\Models\\User', user_id: this.userId}
            this.$store.dispatch('customTargetValue/clearItem')
        }
    },

    created() {
        this.$store.dispatch('customTargetValue/all', {user_type: 'App\\Models\\User', user_id: this.userId, with: []})
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{ $tc('projects::custom_target_values.custom_target_value', 2).ucFirst() }}</h5>
        </div>
        <div class="card-body bg-light">
            <simple-table :items="items" :fields="fields">
                <template #icon="{value}">
                    <i :class="value"></i>
                </template>
                <template #actions="{item, edit}">
                    <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="editTableItem(item, edit)">
                        <i class="fas fa-edit"></i>
                    </span>
                        <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removeTableItem(item)">
                        <i class="fas fa-trash-alt"></i>
                    </span>
                </template>
                <template #default="{close, item}">
                    <custom-target-value-form :item="item" :user-id="userId" do-not-save  @update="updated => updateTableItem(updated, close)">
                        <template #buttons>
                            <b-button class="ms-2" variant="outline-secondary" @click="close">{{ $t('base.cancel').ucFirst() }}</b-button>
                        </template>
                    </custom-target-value-form>
                </template>
            </simple-table>
            <transition-toggle>
                <custom-target-value-form v-if="showForm" :item="item" :user-id="userId" do-not-save  class="border rounded p-3 bg-white" @add="add">
                    <template #buttons>
                        <b-button class="ms-2" variant="outline-secondary" @click="showForm = !showForm">{{ $t('base.cancel').ucFirst() }}</b-button>
                    </template>
                </custom-target-value-form>
            </transition-toggle>
            <transition-toggle>
                <div class="col-12 mb-2 text-center" v-if="!showForm">
                    <b-button variant="success" @click="showForm = !showForm">{{ $t('base.add_new').ucFirst() }}</b-button>
                </div>
            </transition-toggle>
        </div>
    </div>
</template>

<style scoped>

</style>
