<template>
    <div class="card">
        <b-overlay v-model="loading">
            <div class="card-header">
                <h4 class="card-title">{{ $tc('projects::quantity_target_values.quantity_target_value', 1).ucFirst() }}</h4>
            </div>
            <div class="card-body">
                <b-form @submit.prevent>
                    <div class="row">
                        <div class="col-lg-3">
                            <label
                                :class="{'text-danger': states.name === false}"
                                class="form-label"
                                for="name"
                            >{{ $t('projects::custom_target_values.columns.name').ucFirst() }}</label
                            >
                            <input
                                id="name"
                                v-model="model.name"
                                :class="{'border-danger': states.name === false}"
                                :placeholder="$t('projects::custom_target_values.placeholders.name').ucFirst()"
                                class="form-control"
                                name="name"
                                type="text"
                            />
                            <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                        </div>
                        <div class="col-lg-3">
                            <label
                                :class="{'text-danger': states.key === false}"
                                class="form-label"
                                for="name"
                            >{{ $t('projects::custom_target_values.columns.key').ucFirst() }}</label
                            >
                            <input
                                id="name"
                                v-model="model.key"
                                :class="{'border-danger': states.key === false}"
                                :placeholder="$t('projects::custom_target_values.placeholders.key').ucFirst()"
                                class="form-control"
                                name="key"
                                type="text"
                            />
                            <div :class="{'d-block': states.key === false}" class="invalid-feedback">{{ errors.key }}</div>
                        </div>
                        <div class="col-lg-3 mb-4">
                            <label
                                class="form-label"
                                for="type"
                            >{{ $t('base.type').ucFirst() }}</label>
                            <select
                                id="type"
                                v-model="type"
                                class="form-select"
                                name="type"
                            >
                                <option :value="null">
                                    {{ $t('base.please_select').ucFirst() }}
                                </option>
                                <option v-for="(item, index) in types" :key="index" :value="item.value">{{ item.text }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.daily === false || states.weekly === false || states.monthly === false || states.yearly === false}"
                                class="form-label"
                                for="numericValue"
                            >{{ $tc('projects::custom_target_values.columns.value', 1).ucFirst() }}</label
                            >
                            <input
                                id="numericValue"
                                v-model="numericValue"
                                :class="{'border-danger': states.daily === false || states.weekly === false || states.monthly === false || states.yearly === false}"
                                :placeholder="$tc('projects::custom_target_values.placeholders.value', 1).ucFirst()"
                                class="form-control"
                                type="number"
                            />
                            <div :class="{'d-block': states.daily === false || states.weekly === false || states.monthly === false || states.yearly === false}" class="invalid-feedback">{{ allError }}</div>
                        </div>
                        <div class="col-lg-1">
                            <icon-input
                                id="icon"
                                v-model="model.icon"
                                :class="{'border-danger': states.icon === false}"
                                :placeholder="$t('projects::custom_target_values.placeholders.icon').ucFirst()"
                                class="form-control"
                                name="icon"
                                :state="states.icon"
                                :error="errors.icon"
                            >{{ $t('projects::custom_target_values.columns.icon').ucFirst() }}</icon-input>
                        </div>
                    </div>
                </b-form>
            </div>
            <!-- end card body -->
            <div class="card-footer">
                <b-button variant="primary" @click="save">{{ $t('base.save').ucFirst() }}</b-button>
                <slot name="buttons"></slot>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import IconInput from "../elements/icon-input.vue";

export default {
    name: "CustomTargetValueForm",
    components: {IconInput},

    emits: ['saved', 'update', 'add'],

    props: {
        item: {
            type: Object,
            required: true
        },

        userId: {
            type: [String, Number],
        },

        doNotSave: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'customTargetValue/loading',
            states: 'customTargetValue/states',
            errors: 'customTargetValue/errors'
        }),

        types: function () {
            return [
                {
                    value: 'daily',
                    text: this.$t('dates.daily').ucFirst()
                },
                {
                    value: 'weekly',
                    text: this.$t('dates.weekly').ucFirst()
                },
                {
                    value: 'monthly',
                    text: this.$t('dates.monthly').ucFirst()
                },
                {
                    value: 'yearly',
                    text: this.$t('dates.yearly').ucFirst()
                }
            ]
        },

        allError: function () {
            return Object.keys(this.errors).reduce((string, item) => {
                return this.errors[item] ? (string += ' ' + this.errors[item]) : string;
            }, '');
        },
    },

    data: function () {
        return {
            model: {},
            numericValue: null,
            type: null,
            typeValues: ['daily', 'weekly', 'monthly', 'yearly']
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            if (!this.doNotSave) {
                return this.$store.dispatch(`customTargetValue/${method}`, this.model).then(() => {
                    this.$emit('saved')
                })
            } else {
                if (this.model.id) {
                    this.$emit('update', this.model);
                } else {
                    this.$emit('add', this.model);
                }
            }
        },

        setModel: function (model = {}) {
            const base = {}

            base.user_type = 'App\\Models\\User';

            if (this.userId) {
                base.user_id = this.userId;
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));
        },

        clearModel: function () {
            this.typeValues.forEach(type => {
                if (type !== this.type) {
                    this.model[type] = null;
                }
            })
        },
    },

    watch: {
        item: {
            immediate: true,
            handler: function (item) {
                this.setModel(item)
                this.typeValues.forEach(type => {
                    if (this.model[type]) {
                        this.type = type;
                        this.numericValue = this.model[type];
                    }
                })
                if (!this.type) {
                    this.type = 'monthly';
                }
            }
        },

        numericValue: function (value) {
            if (this.type) {
                this.model[this.type] = value;
                this.clearModel();
            }
        },

        type: function (value) {
            if (this.numericValue) {
                this.model[value] = this.numericValue;
                this.clearModel();
            }
        },
    },

    unmounted() {
        this.$store.dispatch('customTargetValue/clearItem')
    }
}
</script>

<style scoped>
</style>
