<script>
import IconSelect from "./icon-select.vue";

export default {
    name: "many-model-manager",
    components: {IconSelect},

    props: {
        store: {
            type: String,
            requireD: true
        },

        userId: {
            type: [Number, String]
        },

        fields: {
            type: Array
        },

        title: {
            type: String
        },

        currency: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        items: function () {
            return this.$store.getters[this.store + '/all']
        },

        currencies: function () {
            if (this.currency) {
                return this.$store.getters['currency/all']
            }
            return [];
        },

        selectedCurrency: function () {
            return this.$store.getters['currency/item'];
        },

        loading: function () {
            return this.$store.getters[this.store + '/loading'] || this.$store.getters['interaction/loading']
        }
    },

    data: function () {
        return {}
    },

    methods: {
        fetchTargetValues: function () {
            const data = {};

            if (this.currency) {
                data.currency_id = this.selectedCurrency.id
            }

            this.$store.dispatch(this.store + '/userItems', {id: this.userId, query: data}).then(() => {
                this.initItems();
            });
        },

        save: function () {
            this.$store.dispatch(this.store + '/saveMany', this.items)
        },

        updateItem: function (item, index) {
            this.items[index] = item
        },

        initItems: function () {
            this.$store.getters['interaction/all'].forEach(interaction => {
                if (! this.items.find(item => item.interaction_id === interaction.id)) {

                    this.$store.dispatch(this.store + '/push', {
                        interaction_id: interaction.id,
                        user_type: 'App\\Models\\User',
                        user_id: this.userId
                    })
                }
            })

            const items = [...this.items]

            items?.sort((a,b) => {
                const ai = this.$store.getters['interaction/all'].find(i => i.id === a.interaction_id)
                const bi = this.$store.getters['interaction/all'].find(i => i.id === b.interaction_id)

                return ai?.sort < bi?.sort
                    ? -1
                    : (ai?.sort > bi?. sort ? 1 : 0);
            })

            this.$store.dispatch(this.store + '/setAll', items)
        },
    },

    watch: {
        selectedCurrency: {
            immediate: true,
            deep: true,
            handler: function (value) {
                if (this.currency && value?.id) {
                    this.fetchTargetValues()
                }
            },
        }
    },

    created() {
        if (this.currency) {
            this.$store.dispatch('currency/all').then(() => {
                this.fetchTargetValues();
            });
        } else {
            this.fetchTargetValues();
        }
        this.$store.dispatch('interaction/all').then(() => {
            this.initItems();
        });
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-header">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="card-title">{{ title }}</h5>
                    <icon-select v-if="currency" store="currency" notNull content translatable icon="symbol" text="name"></icon-select>
                </div>
            </div>
            <div class="card-body">
                <table class="table table-striped table-hover table-borderless table-sm manager">
                    <thead>
                    <tr>
                        <td class="pb-3 text-center font-weight-bold" v-for="(field, fieldIndex) in fields" :key="fieldIndex">{{ field }}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(item, index) in items" :key="item.id || 'index_' + index">
                        <slot name="form" v-bind:item="item" v-bind:updateItem="updated => updateItem(updated, index)"></slot>
                    </template>
                    </tbody>
                </table>
            </div>
            <div class="card-footer text-center">
                <b-button variant="primary" @click="save">{{ $t('base.save').ucFirst() }}</b-button>
            </div>
        </div>
    </b-overlay>
</template>

<style scoped>

</style>
