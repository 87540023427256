<template>
    <tr>
        <td class="col-lg-4 mb-4">
            <template v-if="model?.interaction_id && interaction">
                <strong>{{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}</strong>
            </template>
            <template v-else>
                <select
                    id="interaction_id"
                    v-model="model.interaction_id"
                    :class="{'border-danger': states.interaction_id === false}"
                    class="form-select form-select-sm"
                    name="interaction_id"
                >
                    <option :value="model.id ? null : undefined">
                        {{ $t('projects::financial_target_values.placeholders.interaction_id').ucFirst() }}
                    </option>
                    <option v-for="(item, key) in interactions" :key="key" :value="item.id">{{
                            item.translation_key ? $t(item.translation_key).ucFirst() : item.name
                        }}
                    </option>
                </select>
                <div :class="{'d-block': states.interaction_id === false}" class="invalid-feedback">
                    {{ errors.interaction_id }}
                </div>
            </template>
        </td>
        <td class="col-lg-4 mb-4">
            <select
                id="type"
                v-model="type"
                class="form-select form-select-sm"
                name="type"
            >
                <option :value="null">
                    {{ $t('base.please_select').ucFirst() }}
                </option>
                <option v-for="(item, index) in types" :key="index" :value="item.value">{{ item.text }}</option>
            </select>
        </td>
        <td class="col-lg-4 mb-4">
            <input
                id="numericValue"
                v-model="numericValue"
                :class="{'border-danger': states.daily === false || states.weekly === false || states.monthly === false || states.yearly === false}"
                :placeholder="$tc('projects::financial_target_values.financial_target_value', 1).ucFirst()"
                class="form-control form-control-sm"
                type="number"
            />
            <div :class="{'d-block': states.daily === false || states.weekly === false || states.monthly === false || states.yearly === false}" class="invalid-feedback">{{ allError }}</div>
        </td>
    </tr>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "FinancialTargetValueLineForm",

    emits: ['saved', 'updated'],

    props: {
        item: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            loading: 'financialTargetValue/loading',
            states: 'financialTargetValue/states',
            errors: 'financialTargetValue/errors',
            interactions: 'interaction/all',
            currency: 'currency/item'
        }),

        types: function () {
            return [
                {
                    value: 'daily',
                    text: this.$t('dates.daily').ucFirst()
                },
                {
                    value: 'weekly',
                    text: this.$t('dates.weekly').ucFirst()
                },
                {
                    value: 'monthly',
                    text: this.$t('dates.monthly').ucFirst()
                },
                {
                    value: 'yearly',
                    text: this.$t('dates.yearly').ucFirst()
                }
            ]
        },

        allError: function () {
            return Object.keys(this.errors).reduce((string, item) => {
                return this.errors[item] ? (string += ' ' + this.errors[item]) : string;
            }, '');
        },

        interaction: function () {
            return this.interactions.find(item => item.id === this.model.interaction_id)
        },
    },

    data: function () {
        return {
            model: {},
            numericValue: null,
            type: 'monthly',
            typeValues: ['daily', 'weekly', 'monthly', 'yearly']
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`financialTargetValue/${method}`, this.model).then(() => {
                this.$emit('saved')
            })
        },

        clearModel: function () {
            this.typeValues.forEach(type => {
                if (type !== this.type) {
                    this.model[type] = null;
                }
            })
        },
    },

    watch: {
        item: {
            immediate: true,
            handler: function (item) {
                this.model = JSON.parse(JSON.stringify(item)) || {}
                this.model.user_type = 'App\\Models\\User'
                this.model.currency_id = this.currency.id
                this.typeValues.forEach(type => {
                    if (this.model[type]) {
                        this.type = type;
                        this.numericValue = this.model[type];
                    }
                })
                // this.fetchTargetValues()
            }
        },

        numericValue: function (value) {
            if (this.type) {
                this.model[this.type] = value;
                this.clearModel();
            }
        },

        type: function (value) {
            if (this.numericValue) {
                this.model[value] = this.numericValue;
                this.clearModel();
            }
        },

        model: {
            deep: true,
            handler: function (value) {
                if (this.item) {
                    this.$emit('updated', value);
                }
            },
        },

        currency: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.model.currency_id = value?.id
            },
        }
    },

    unmounted() {
        this.$store.dispatch('financialTargetValue/clearItem')
    }
}
</script>

<style scoped>
</style>
