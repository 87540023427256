<script>
import FinancialTargetValueLineForm from "./financial-target-value-form.vue";
import QuantityTargetValueLineForm from "./quantity-target-value -line-form.vue";
import ManyModelManager from "../elements/many-model-manager.vue";
import CustomTargetValueManager from "../elements/custom-target-value-manager.vue";

export default {
    name: "target-value-form",
    components: {CustomTargetValueManager, ManyModelManager, QuantityTargetValueLineForm, FinancialTargetValueLineForm},
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    computed: {
        quantityTargetValueFields: function () {
            return [
                '',
                this.$t('base.type').ucFirst(),
                this.$tc('projects::quantity_target_values.quantity_target_value', 1).ucFirst(),
            ]
        },
    }
}
</script>

<template>
    <div class="row" v-if="user?.id">
        <div class="col-xl-6">
            <many-model-manager :user-id="user.id" store="quantityTargetValue" :fields="quantityTargetValueFields" :title="$tc('projects::quantity_target_values.quantity_target_value', 1).ucFirst()">
                <template #form="{item, updateItem}">
                    <quantity-target-value-line-form :item="item" @updated="updateItem"></quantity-target-value-line-form>
                </template>
            </many-model-manager>
        </div>
        <div class="col-xl-6">
            <many-model-manager :currency="true" :user-id="user.id" store="financialTargetValue" :fields="quantityTargetValueFields" :title="$tc('projects::financial_target_values.financial_target_value', 1).ucFirst()">
                <template #form="{item, updateItem}">
                    <financial-target-value-line-form :item="item" @updated="updateItem"></financial-target-value-line-form>
                </template>
            </many-model-manager>
        </div>
        <div class="col-xl-6">
            <custom-target-value-manager :user-id="user.id"></custom-target-value-manager>
        </div>
    </div>
</template>

<style scoped>

</style>
